.content {

  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0px;
  padding: 0px;
}

ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0;
}

/* .canvasbox {
  width: 720px;
} */

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top:0px;
  margin-bottom:0px;
  padding-top:16px;
}

.banner-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-right:10px;
  margin-top:10px;
  margin-left:10px;
}

.navbar-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:0px;
}

.navbar-item {
  margin-right: 20px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  width: 106px;
}

@media screen and (max-width: 512px) {
  /* UNDER 500px CSS here */

  .banner-logo {
    width: 80%;
  }

  .navbar-tabs {
    padding-left: 10px;
    width: 95%;
    overflow-x: hidden;
  }

  .navbar-item {
    margin-right: auto;
    margin-left: auto;
    width: 106px;
  }

  canvas {
    width:100%;
  }
}

.mainbox {
  display: flex;
  justify-content: center;
  width: 500px;
  min-width:0px;
  min-height:400px;
  padding:0px;
  margin:0px;
}

.maintext {
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 100%;
  font-family: mainnewsmol;
}

.maintext p {
  text-align: left;
}

.bigmargintext {
  margin-bottom: 50rem;
  font-size: 100%;
  font-family: mainnewsmol;
}

.footer {
  position: "fixed";
  display: flex;
  margin: auto;
  justify-content: center;
  font-family: mainnewsmol;
  padding-top:4rem;
  padding-bottom:2rem;
}

.footer-item {
  position: "fixed";
  padding: 4px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 100%;
  image-rendering: auto;
}

.pixel {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-pixelated;
  image-rendering: pixelated;
}

* {
  aspect-ratio: unset;
  /* background-color: rgb(215, 206, 255);
  background-color: rgb(0, 0, 0); */
  color:#6eeffc;
}

.parallax {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -60;
}

@font-face {
  font-family: "mainnewsmol";
  src: local("mainnewsmol"),
   url("./assets/fonts/mainnewsmol.ttf") format("truetype");
 }
